import type Vue from 'vue'
import { RouteConfig } from 'vue-router'

function generateRoutes (app: Vue) {
  const routes = [] as RouteConfig[]

  // if (app.$store.state.auth.user.is_super) {
  routes.push({
    path: '/contents',
    name: 'base-management.modules',
    component: () => import(/* webpackChunkName: "modules-views" */'../views/ModuleListView.vue'),
    meta: {
      breadcrumbs: {
        text: 'module.global.modules'
      }
    }
  })
  // }

  routes.push({
    path: '/content',
    component: { render: h => h('RouterView') },
    meta: {
      breadcrumbs: {
        text: 'module.global.modules',
        to: '/contents'
      }
    },
    children: [{
      path: ':id/edit/:view?',
      name: 'base-management.module.edit',
      component: () => import(/* webpackChunkName: "modules-views" */'../views/ModuleEdit.vue'),
      props: true,
      meta: {
        breadcrumbs: {
          text: 'core.action.edit',
          to: null
        }
      }
    }]
  }, {
    path: '/content-preview/:moduleId',
    name: 'base-management.module.preview',
    component: () => import(/* webpackChunkName: "modules-views" */'../views/ModuleView.vue'),
    props: true,
    meta: {
      breadcrumbs: {
        text: 'core.action.preview',
        to: null
      }
    }
  })

  return routes
}

const eagerRoutes = [{
  path: '/content/:runIdentifier/:moduleIdentifier',
  name: 'base-management.module.view',
  component: () => import(/* webpackChunkName: "modules-views" */'../views/ModuleView.vue'),
  meta: {
    guest: true,
    noCookieConsent: true
  },
  props: true
}, {
  path: '/qr/content/:runIdentifier/:moduleIdentifier/:code',
  name: 'base-management.module-qr.view',
  component: () => import(/* webpackChunkName: "runs-eager-views" */'../views/ModuleQrView.vue'),
  props: true,
  meta: {
    guest: true,
    appBarComponent: null
  }
}] as RouteConfig[]

export {
  generateRoutes,
  eagerRoutes
}
