import { MutationTree } from 'vuex'
import { MUTATIONS } from './consts'
import StateModule from './types'

const modules = {
  [MUTATIONS.SET_SESSION]: (state, payload: any) => {
    state.sessions[payload.runId + '_' + payload.moduleId] = payload.session
  },
  [MUTATIONS.SET_TEST]: (state, payload: boolean) => {
    state.isTest = payload
  },
  [MUTATIONS.SET_SHOW_TEST_EVALUATION]: (state, payload: boolean) => {
    state.showTestEvaluation = payload
  }
} as MutationTree<StateModule>

export default modules
