import { Module } from 'vuex'

import state from './state'
import getters from './getters'
import actions from './actions'
import mutations from './mutations'
import StateModule from './types'

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
} as Module<StateModule, any>
