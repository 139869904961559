import { ActionTree } from 'vuex'
import StateModule from './types'
import { apolloProvider } from '@simpl/core/plugins/apollo'
import { ACTIONS } from './consts'
import { STORE_SESSION_TRACKING } from '@simpl/core/graphql'

export default {
  [ACTIONS.UPDATE_TRACKING_DATA]: async ({ commit, state, getters }, payload: any) => {
    const session = getters.getSession(payload.runId, payload.moduleId)
    const data: Record<string, any> = {}
    data.token = session.token
    data.trackings = []
    data.trackings.push({
      type: 'generic',
      objective: payload.data.objective + '',
      site: payload.data.objective + '',
      key: payload.data.key + '',
      score: payload.data.score,
      data: JSON.stringify({ value: payload.data.data })
    })
    return new Promise((resolve, reject) => {
      apolloProvider.defaultClient.mutate({
        mutation: STORE_SESSION_TRACKING,
        variables: {
          data: data
        },
        context: {
          headers: {
            simplsession: session.token
          }
        }
      }).then((resp: any) => {
        // console.log('success', resp)
        resolve(resp)
      }).catch((error: Error) => {
        console.error('reject', error)
        reject(error)
      })
    })
  }
} as ActionTree<StateModule, any>
