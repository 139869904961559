import { GetterTree } from 'vuex'
import StateModule from './types'
import { Session } from '@simpl/core/types/graphql'

export default {
  getSession: (state) => (runId: number, moduleId: number) => {
    if (state.sessions[runId + '_' + moduleId]) {
      return state.sessions[runId + '_' + moduleId]
    }
    return null
  },
  getTracking: (state) => (runId: number, moduleId: number, objective: string, key: string) => {
    const session: Session | null = state.sessions[runId + '_' + moduleId]
    if (session.trackings) {
      let index: any
      for (index in session.trackings) {
        const tracking = session.trackings[index]
        if (tracking && tracking.objective === String(objective) && tracking.key === String(key)) {
          return tracking.data
        }
      }
    }
    return false
  },
  getSessions: (state) => (runId: string) => {
    const sessions = []
    for (const key in state.sessions) {
      if (key.substr(0, key.indexOf('_')) === runId) {
        sessions.push(state.sessions[key])
      }
    }
    return sessions
  },
  showTestEvaluation: (state) => {
    return state.isTest && state.showTestEvaluation
  }
} as GetterTree<StateModule, any>
